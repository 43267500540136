<template>
  <div class="relative wrapper">
    <div class="selectContainer">
      <div :id="props.context.id">
        <v-select
          ref="mySelect"
          v-model="value"
          :options="props.context.selectOptions"
          :searchable="false"
          :placeholder="placeholderLabel"
          :clearable="false"
          :disabled="context.disabled"
          :loading="context.isLoading"
          :data-testid="context.testid"
        >
          <template v-if="$slots['selected-option']" #selected-option="option">
            <slot
              :data-testid="context.testid"
              name="selected-option"
              v-bind="option as any"
            />
          </template>
          <template v-if="$slots.option" #option="option">
            <slot name="option" v-bind="option as any" />
          </template>
          <template #spinner="{ loading }">
            <Icon
              v-if="loading"
              icon-class="fad fa-spinner-third"
              class="text-xl relative top-[-2px] left-[9px] animate-spin text-primary-base"
            />
          </template>
        </v-select>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { toRef } from 'vue';
import { VueSelect as vSelect } from 'vue-select';
import { Icon } from '@/complib';

import OpenIndicator from './components/OpenIndicator.vue';

const { t } = useTrans();
const props = defineProps({
  // eslint-disable-next-line vue/require-default-prop
  context: Object,
});

const mySelect = ref(null);
const contextRef = toRef(props, 'context');

const placeholderLabel = contextRef.value?.customPlaceholder
  ? contextRef.value.customPlaceholder
  : t('customSelect.placeholder');

/**
 * !! WARNING !!
 * dropdown reactivity is fickle so DONT touch this if you are not 100% percent sure why this works
 */
watch(contextRef.value, () => handleChange());

const value = ref(
  props.context.selectedOption ? props.context.selectedOption : null,
);

watch(
  () => contextRef.value.selectedOption,
  (nv) => {
    value.value = nv;
  },
);

watch(
  () => value.value,
  (nv) => {
    props.context.node.input(nv);
  },
);

function handleChange() {
  if (!contextRef.value._value) {
    if (mySelect.value) {
      mySelect.value.clearSelection();
    }
  }
}

(vSelect.props.components as any).default = () => ({ OpenIndicator });

onMounted(() => {
  if (props.context.selectedOption) {
    props.context.node.input(props.context.selectedOption.value);
  }
});
</script>

<style lang="postcss" scoped>
:global(:root) {
  --vs-dropdown-color: var(--thm-text-base);
  --vs-dropdown-option-color: var(--thm-text-base);

  --vs-selected-color: var(--thm-text-base);
  --vs-search-input-color: var(--thm-text-base);

  --vs-dropdown-option--active-bg: var(--thm-primary-lightest);
  --vs-dropdown-option--active-color: var(--thm-text-base);
  --vs-selected-bg: var(--thm-primary-base);
  --vs-search-input-placeholder-color: var(--thm-text-base);

  --vs-dropdown-option--selected-bg: var(--thm-primary-base);
  --vs-state-disabled-bg: white;
}
.wrapper :deep(.selectContainer) {
  .vs__actions {
    @apply p-0;
  }
  .vs__dropdown-option--selected {
    background-color: var(--thm-primary-base);
    color: white;
  }
  .vs__dropdown-menu {
    @apply p-0 top-[35px] border-none rounded-none !rounded-br-alt-sm !rounded-bl-alt-sm left-0 right-0;
    box-shadow: 0px 3px 6px #00000029;
  }
  .v-select {
    --vs-dropdown-min-width: 110px;
    @apply bg-white border-border-light border border-b-[3px] h-9 px-2 md:px-4 py-[9px] text-md text-text-base ring-0 rounded-alt-sm;
    position: initial;
  }
  .v-select.vs--open {
    @apply border-b-primary-base min-h-[36px] rounded-br-none rounded-bl-none;
  }
  .vs__selected {
    @apply m-0 p-0 border-none !opacity-100 !h-4 !leading-4;
  }
  .vs__dropdown-toggle {
    @apply p-0 border-l-0 border-r-0 border-t-0 border-b-0 rounded-none;
  }
  .vs__dropdown-option {
    @apply flex items-center h-9;
  }
  /** when hidden with display: none this breaks click behavior, cf. https://github.com/sagalbot/vue-select/issues/294 */
  .vs__search {
    @apply !w-0 !h-4 !p-0 !border-none m-0 pointer-events-none focus:m-0;
  }
  .vs__actions {
    @apply flex content-start;
  }
  .vs__open-indicator {
    @apply flex;
  }
}
</style>
